var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "mb-3" }, [_vm._v("Bulk Edit Options")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 mb-3" }, [
          _c("label", { attrs: { for: "bulk_teleprompt" } }, [
            _vm._v("Teleprompter Text"),
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.teleprompt,
                expression: "formData.teleprompt",
              },
            ],
            staticClass: "form-control",
            attrs: {
              id: "bulk_teleprompt",
              rows: "3",
              placeholder:
                "Hi {first_name}, I wanted to personally reach out...",
            },
            domProps: { value: _vm.formData.teleprompt },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "teleprompt", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 mb-3" }, [
          _c("label", { attrs: { for: "bulk_title" } }, [_vm._v("Title")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.title,
                expression: "formData.title",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", id: "bulk_title" },
            domProps: { value: _vm.formData.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "title", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6 mb-3" }, [
          _c("label", { attrs: { for: "bulk_message" } }, [_vm._v("Message")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.message,
                expression: "formData.message",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", id: "bulk_message" },
            domProps: { value: _vm.formData.message },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "message", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 mb-3" }, [
          _c("label", { attrs: { for: "bulk_btn_title" } }, [
            _vm._v("Button Title"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.btn_title,
                expression: "formData.btn_title",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", id: "bulk_btn_title" },
            domProps: { value: _vm.formData.btn_title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "btn_title", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6 mb-3" }, [
          _c("label", { attrs: { for: "bulk_btn_link" } }, [
            _vm._v("Button Link"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.btn_link,
                expression: "formData.btn_link",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "bulk_btn_link",
              placeholder: "https://",
            },
            domProps: { value: _vm.formData.btn_link },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "btn_link", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Please include https:// in your URL"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6 mb-3" },
          [
            _c("label", [_vm._v("Schedule Send Time")]),
            _vm._v(" "),
            _c("date-picker", {
              attrs: {
                type: "datetime",
                "min-date": _vm.minDate,
                "max-date": _vm.maxDate,
                "hour-step": 1,
                "minute-step": 15,
                "time-picker-options": {
                  start: "06:00",
                  step: "00:15",
                  end: "20:00",
                },
                "disabled-date": (date) => date < new Date(),
                format: "MM/DD/YY hh:mm A",
                placeholder: "Select date and time",
                "value-type": "timestamp",
              },
              on: { change: _vm.handleDateSelection },
              model: {
                value: _vm.formData.scheduledDateTime,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "scheduledDateTime", $$v)
                },
                expression: "formData.scheduledDateTime",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bulk_teleprompt" },
        domProps: { value: _vm.formData.teleprompt },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bulk_title" },
        domProps: { value: _vm.formData.title },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bulk_message" },
        domProps: { value: _vm.formData.message },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bulk_btn_title" },
        domProps: { value: _vm.formData.btn_title },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bulk_btn_link" },
        domProps: { value: _vm.formData.btn_link },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "bulk_scheduled_datetime" },
        domProps: { value: _vm.formattedScheduledDateTime },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-info mb-4" }, [
      _c("h6", { staticClass: "mb-2" }, [
        _c("i", { staticClass: "fas fa-info-circle" }),
        _vm._v(" Available Merge Tags:"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-1" }, [
        _vm._v("Use these tags in the content to personalize your Videoup:"),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "mb-0" }, [
        _c("li", [
          _c("code", [_vm._v("{first_name}")]),
          _vm._v(" - Inserts the customer's first name"),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("code", [_vm._v("{last_name}")]),
          _vm._v(" - Inserts the customer's last name"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-2 mb-0" }, [
        _c("small", [
          _vm._v('Example: "Hello {first_name}, thank you for..."'),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }