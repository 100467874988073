<template>
    <div class="card">
        <div class="card-body">
            <h5 class="mb-3">Bulk Edit Options</h5>

            <div class="alert alert-info mb-4">
                <h6 class="mb-2"><i class="fas fa-info-circle"></i> Available Merge Tags:</h6>
                <p class="mb-1">Use these tags in the content to personalize your Videoup:</p>
                <ul class="mb-0">
                    <li><code>{first_name}</code> - Inserts the customer's first name</li>
                    <li><code>{last_name}</code> - Inserts the customer's last name</li>
                </ul>
                <p class="mt-2 mb-0"><small>Example: "Hello {first_name}, thank you for..."</small></p>
            </div>
            
            <div class="row">
                <div class="col-md-12 mb-3">
                    <label for="bulk_teleprompt">Teleprompter Text</label>
                    <textarea 
                        v-model="formData.teleprompt" 
                        id="bulk_teleprompt" 
                        class="form-control" 
                        rows="3" 
                        placeholder="Hi {first_name}, I wanted to personally reach out..."
                    ></textarea>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="bulk_title">Title</label>
                    <input 
                        type="text" 
                        v-model="formData.title" 
                        id="bulk_title" 
                        class="form-control"
                    >
                </div>
                <div class="col-md-6 mb-3">
                    <label for="bulk_message">Message</label>
                    <input 
                        type="text" 
                        v-model="formData.message" 
                        id="bulk_message" 
                        class="form-control"
                    >
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="bulk_btn_title">Button Title</label>
                    <input 
                        type="text" 
                        v-model="formData.btn_title" 
                        id="bulk_btn_title" 
                        class="form-control"
                    >
                </div>
                <div class="col-md-6 mb-3">
                    <label for="bulk_btn_link">Button Link</label>
                    <input 
                        type="text" 
                        v-model="formData.btn_link" 
                        id="bulk_btn_link" 
                        class="form-control" 
                        placeholder="https://"
                    >
                    <small class="text-muted">Please include https:// in your URL</small>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mb-3">
                    <label>Schedule Send Time</label>
                    <date-picker
                        v-model="formData.scheduledDateTime"
                        type="datetime"
                        :min-date="minDate"
                        :max-date="maxDate"
                        :hour-step="1"
                        :minute-step="15"
                        :time-picker-options="{
                            start: '06:00',
                            step: '00:15',
                            end: '20:00'
                        }"
                        :disabled-date="(date) => date < new Date()"
                        format="MM/DD/YY hh:mm A"
                        placeholder="Select date and time"
                        value-type="timestamp"
                        @change="handleDateSelection"
                    ></date-picker>
                </div>
            </div>

            <!-- Hidden form fields to pass data back to Laravel -->
            <input type="hidden" name="bulk_teleprompt" :value="formData.teleprompt">
            <input type="hidden" name="bulk_title" :value="formData.title">
            <input type="hidden" name="bulk_message" :value="formData.message">
            <input type="hidden" name="bulk_btn_title" :value="formData.btn_title">
            <input type="hidden" name="bulk_btn_link" :value="formData.btn_link">
            <input type="hidden" name="bulk_scheduled_datetime" :value="formattedScheduledDateTime">
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: 'BulkEditOptions',
    components: {
        DatePicker
    },
    data() {
        return {
            formData: {
                teleprompt: '',
                title: '',
                message: '',
                btn_title: '',
                btn_link: '',
                scheduledDateTime: null
            }
        };
    },
    computed: {
        minDate() {
            const now = new Date();
            const minDate = new Date(now);
            
            if (now.getHours() >= 20) {
                minDate.setDate(minDate.getDate() + 1);
                minDate.setHours(6, 0, 0, 0);
            } else if (now.getHours() < 6) {
                minDate.setHours(6, 0, 0, 0);
            }
            return minDate;
        },
        maxDate() {
            const now = new Date();
            return new Date(now.setFullYear(now.getFullYear() + 1));
        },
        formattedScheduledDateTime() {
            if (!this.formData.scheduledDateTime) return '';
            const localDate = new Date(this.formData.scheduledDateTime);
            return localDate.toISOString().slice(0, 19).replace('T', ' ');
        }
    },
    methods: {
        handleDateSelection(date) {
            if (!date) {
                this.formData.scheduledDateTime = null;
                return;
            }

            const selectedDate = new Date(date);
            const selectedHour = selectedDate.getHours();
            
            if (selectedHour < 6) {
                selectedDate.setHours(6, 0, 0, 0);
            } else if (selectedHour >= 20) {
                selectedDate.setHours(19, 59, 0, 0);
            }
            
            const minutes = selectedDate.getMinutes();
            const roundedMinutes = Math.round(minutes / 15) * 15;
            selectedDate.setMinutes(roundedMinutes, 0, 0);
            
            this.formData.scheduledDateTime = selectedDate.getTime();
        }
    }
};
</script>

<style lang="scss">
.mx-datepicker {
    width: 100%;
}
</style> 